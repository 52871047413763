"use strict";

import axios from "axios";
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import {createApp, ref, onMounted} from 'vue';
import dynamicModal from './components/dynamic-modal.vue'

export function init () {
    onFind('.js-dynamic-modal', function (element) {
        let vueApp = createApp({
            components: {
                dynamicModal
            },
            setup() {
                let url = ref('www.test.at');
                let showModal = ref(false);
                let loading = ref(false);
                let content = ref(false);
                let error = ref(false);

                const handleClick = () => {
                    loading.value = true;

                    showModal.value = true;

                    document.body.classList.add("body--modal-open");

                    axios({method: 'GET', url: url.value, headers: {'X-Requested-With': 'XMLHttpRequest'}})
                        .then((res) => res.data)
                        .then(res => {
                            if (res.success) {
                                if(content.value !== res.html){
                                    content.value = res.html;
                                    error.value = false;
                                }
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            error.value = true;
                        })
                        .finally(() => loading.value = false)
                }
                return {
                    url,
                    showModal,
                    handleClick,
                    loading,
                    content,
                    error };
            }
        });
        vueApp.mount(element);
    });
}