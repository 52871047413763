"use strict";
import {findAll, on, closest, addClass, removeClass, findIn, findAllIn, setAttribute, removeAttribute} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";


export function init() {
    onFind('.js-calculate-route', function(element) {
        const submitHandler = (evt, calculateRoute) => {
            evt.preventDefault();
            let destinationAddress;

            let input = findIn('.js-calculate-route__input:checked', calculateRoute);
            if(input != null){

                destinationAddress = input.value;
            }
            else{
                destinationAddress = calculateRoute.getAttribute('data-destination-address');
            }
            let calculateRouteValue = findIn('.js-calculate-route__origin', calculateRoute).value;
            let googleMapsRouteUrl = 'https://www.google.com/maps/dir/?api=1&origin=' + calculateRouteValue + '&destination=' + destinationAddress;

            window.open(googleMapsRouteUrl, '_blank');
        };

        on('submit', (evt) => submitHandler(evt, element), element);
    });
}