import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as dynamicLightbox from './scripts/dynamic-lightbox';
dynamicLightbox.init();

import * as dynamicModal from './scripts/dynamic-modal';
dynamicModal.init();

import * as video from './scripts/video';
video.init();

import * as asyncSlider from './scripts/async-slider';
asyncSlider.init();

import * as calculateRoute from './scripts/calculate-route';
calculateRoute.init();

import * as searchTeaser from './scripts/search-teaser';
searchTeaser.init();

import * as sharing from './scripts/sharing';
sharing.init();