import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, hasClass, addClass, removeClass, on, off, trigger, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-search-teaser', (teaser) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let toCheck = findAllIn('.js-search-teaser__content', teaser);

        if(urlParams.get('q')){
            toCheck.map((item) => {
                item.innerHTML = highlight(item.textContent, urlParams.get('q'));
            });
        }
    });
}

function highlight(content, search) {
    let regexp = new RegExp(search, "gim");
    let newContent = content;

    newContent = newContent.replace(regexp,  function(m) {
        return /[A-Z]/.test(m) ? "<strong>"+ capitalize(search) +"</strong>" : "<strong>"+ search +"</strong>"
    });

    return newContent;
}
function capitalize(x) {
    return x[0].toUpperCase() + x.slice(1)
}